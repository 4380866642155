<template>

    <div class="main">
        <div class="search">

            <el-select size="mini" style="width: 150px;margin-left: 5px" v-model="league.gameTypeId"
                       @change="getLeagueList" placeholder="종목별">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option v-for="item in gameTypeList" :value="item.id" :label="item.typeName">{{item.typeName}}</el-option>
            </el-select>

            <!--상태-->
            <el-select size="mini" style="width: 80px;margin-left: 5px" v-model="league.status"
                       @change="getLeagueList" placeholder="리그상태">
                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
            </el-select>
            <el-select size="mini" style="width: 150px;margin-left: 5px" v-model="league.autoable"
                       @change="getLeagueList" placeholder="경기자동등록여부선택">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option :value="managerConst.ENABLE" label="정상">자동등록</el-option>
                <el-option :value="managerConst.DISABLE" label="차단">수동등록</el-option>
            </el-select>
            <el-select size="mini" style="width: 150px;margin-left: 5px" v-model="league.popular"
                       @change="getLeagueList" placeholder="인기리그여부선택">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option :value="managerConst.NOT" label="일반">일반</el-option>
                <el-option :value="managerConst.YES" label="인기">인기</el-option>
            </el-select>

            <el-popover placement="bottom-end"
                        width="1680"
                        visible-arrow="true"
                        trigger="click"
                        popper-class="bg-title"
                        v-model="visible">
                <div class="ongoingleagues">
                    <div class="lg">
                        <el-button @click="matchAreaSelected(null)" size="mini" type="warning">전체</el-button>
                    </div>
                    <div class="lg">
                        <div v-for="(item,index) in matchAreaList" :key="index"
                             @click="matchAreaSelected(item.areaName)">
                            <span style="margin-left: 5px">{{item.areaName}}</span>
                        </div>
                    </div>

                </div>
                <el-button
                        size="mini" style="margin-left: 5px" slot="reference">경기구역
                </el-button>
            </el-popover>


            <el-input size="mini" style="width: 200px;margin-left: 30px" v-model="league.searchString"></el-input>
            <el-button
                    size="mini" style="margin-left: 5px" @click="getLeagueList" type="primary">리그검색
            </el-button>

            <el-button
                    size="mini" style="margin-left: 40px" @click="editLeague(null,managerConst.MODE_SAVE)" type="success">리그등록
            </el-button>

        </div>

        <div class="data">
            <el-table
                    :data="leagueList"
                    style="width: 100%"
                    max-height="730"
                    border>

                <!--번호-->
                <el-table-column
                        fixed
                        label="번호"
                        width="60">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="이미지"
                        width="60">
                    <template slot-scope="scope">
                        <img :src="scope.row.imageUrl"
                             style="width: 28px;max-height:19px;vertical-align: middle">
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="구역"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.areaName}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="종목"
                        width="70">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gameType">{{scope.row.gameType.typeName}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="리그명"
                        width="220">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="editLeague(scope.row.id,managerConst.MODE_EDIT)">{{scope.row.leagueName}}
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="정열순위"
                        width="85">
                    <template slot-scope="scope">
                        {{scope.row.sort}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="인기리그"
                        width="85">
                    <template slot-scope="scope">
                       <span v-if="scope.row.popular == managerConst.YES" class="badge badge-success">인기</span>
                        <span v-if="scope.row.popular == managerConst.NOT">일반</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="상태"
                        width="85">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == managerConst.ENABLE" class="badge badge-success">정상</span>
                        <span v-if="scope.row.status == managerConst.DISABLE">차단</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="경기자동등록"
                        width="85">
                    <template slot-scope="scope">
                        <span v-if="scope.row.autoable == managerConst.ENABLE" class="badge badge-success">자동</span>
                        <span v-if="scope.row.autoable == managerConst.DISABLE">x</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="수정"
                        width="85">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="editLeague(scope.row.id,managerConst.MODE_EDIT)">수정
                        </el-button>
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        fixed-->
<!--                        label="매칭"-->
<!--                        width="85">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini" type="primary" @click="editMatchingLeague(item.id,item.leagueName,item.leagueKrname)">-->
<!--                            매칭하기-->
<!--                        </el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        label="추가배당(승무패)"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.extraodds}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="추가배당(핸디)"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.extraodds1}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="추가배당(오언)"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.extraodds2}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="추가배당(스페셜 승무패)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.specextraodds1}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="추가배당(스페셜 핸디)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.specextraodds2}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="추가배당(스페셜 오언)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.specextraodds3}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="핸디최소배당(풀타임)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.fhlowodds}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="오언최소배당(풀타임)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.foulowodds}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="핸디최소배당(하프타임)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.hhlowodds}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="오언최소배당(하프타임)"
                        width="140">
                    <template slot-scope="scope">
                        {{scope.row.houlowodds}}
                    </template>
                </el-table-column>


            </el-table>


        </div>

        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>


</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {getLeagueList, getMatchAreaList} from "../../network/manager/leagaueRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {getGameTypeList} from "../../network/manager/gameTypeRequest";
    import Vue from "vue";
    import LeagueEditToast from "../../components/administrator/SportsPanel/LeagueEditToast";
    import {Loading} from "element-ui";
    Vue.prototype.$leagueEditToast = LeagueEditToast
    export default {
        name: "ManageLeague",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                visible:false,
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                league: {},
                leagueList: [],
                gameTypeList:[],
                matchAreaList:[],
            }
        },

        methods: {
            getLeagueList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                getLeagueList(this.league, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.leagueList = res.data.data
                    this.pageTotal =  res.data.total
                    loadingInstance.close()
                })
            },
            editLeague(id,mode){
                this.$leagueEditToast({'leagueId': id, 'mode': mode})
            },
            editMatchingLeague(id,leaugeName,leagueKRName){

            },
            matchAreaSelected(name){
                this.league.areaName = name;
                this.getLeagueList()
                this.visible = false

            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLeagueList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLeagueList();
            },
        },
        created() {
            getGameTypeList().then(res => {
                this.gameTypeList = res.data.data;
            })
            getMatchAreaList().then(res=>{
                this.matchAreaList = res.data.data;
            })
            this.league.status = managerConst.ENABLE
            this.getLeagueList()
            this.$bus.$on('leagueEdited',()=>{
                this.getLeagueList()
            })
        },

    }
</script>

<style scoped>

</style>